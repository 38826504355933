import React from 'react'
import Seo from '../components/utils/Seo'
import { AnimatedPage } from '../components/utils/AnimatedPage'

const NotFoundPage = (props) => {
  return (
    <AnimatedPage>
      <Seo title="404: Not found" {...props.international} />
      <h1>404 NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </AnimatedPage>
  )
}

export default NotFoundPage
