import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from '@react-spring/web'
import { SliderContext } from '../../global-state'

export const AnimatedPage = ({ children = null, className = '' }) => {
  const { slide } = useContext(SliderContext) || { setSlide: false }
  const [show, setShow] = useState(false)
  const animation = useSpring({
    opacity: show ? 1 : 0,
    config: config.molasses,
  })
  useEffect(() => {
    setShow(true)
  }, [show])

  return !slide ? (
    <animated.div className={className} style={animation}>
      {children && children}
    </animated.div>
  ) : (
    <>{children && children}</>
  )
}

AnimatedPage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}
